import React, { useState } from "react";
import { nanoid } from 'nanoid';
import Image from "react-image-enlarger";

export const InputFile = ( {selectedfile, SetSelectedFile, disabled_ = false} ) => {
  const [Files, SetFiles] = useState([]);

  const filesizes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  const InputChange = (e) => {
    let images = [];
    let lastFile = e.target.files[0];
    
    if (lastFile.size > 6048576) { // 5 MB in bytes
      console.log('No se permiten archivos de tamaño mayor a 5MB');
      e.target.value = ''; // clear the file input
    } else if (Object.keys(selectedfile).length > 4) {
      console.log("Se permiten subir como maximo 5 archivos");
      e.target.value = '';
    } else {
      for (let i = 0; i < e.target.files.length; i++) {
        images.push((e.target.files[i]));
        let reader = new FileReader();
        let file = e.target.files[i];
        reader.onloadend = () => {
          SetSelectedFile((preValue) => {
            return [
              ...preValue,
              {
                id: nanoid(),
                filename: e.target.files[i].name,
                filetype: e.target.files[i].type,
                fileimage: reader.result,
                datetime: e.target.files[i].lastModifiedDate.toLocaleString('es-AR'),
                filesize: filesizes(e.target.files[i].size)
              }
            ]
          });
        }
        if (e.target.files[i]) {
          reader.readAsDataURL(file);
        }
      }
  }
  }

  const DeleteSelectFile = (id) => {
    const result = selectedfile.filter((data) => data.id !== id);
    SetSelectedFile(result);
  }

  const FileUploadSubmit = async (e) => {
    e.preventDefault();

    // form reset on submit 
    e.target.reset();
    if (selectedfile.length > 0) {
      for (let index = 0; index < selectedfile.length; index++) {
        SetFiles((preValue) => {
          return [
            ...preValue,
            selectedfile[index]
          ]
        })
      }
      SetSelectedFile([]);
    } else {
      alert('Please select file')
    }
  }

  const DeleteFile = async (id) => {
    const result = Files.filter((data) => data.id !== id);
    SetFiles(result);
  }
  const [style, setStyle] = useState({ display: 'none' });

  function SingleSource({ src }) {
    const [zoomed, setZoomed] = React.useState(false);
    return (
      <div style={{ margin: "0.25rem" }}>
        <Image
          style={{ width: "300px", height: "auto" }}
          zoomed={zoomed}
          src={src}
          onClick={() => setZoomed(true)}
          onRequestClose={() => setZoomed(false)}
        />
      </div>
    );
  }

  return (

    <div className="fileupload-view">
      <div className="w-full">
        <div className="card mt-2">
          <div className="card-body">
            <div className="kb-data-box">

              <form onSubmit={FileUploadSubmit}>
                <div className="kb-file-upload">
                  <div className="file-upload-box">
                    {!disabled_ && <input type="file" id="fileupload" className="file-upload-input" onChange={InputChange} multiple />}
                    {disabled_ && <input type="file" id="fileupload" className="file-upload-input" onChange={InputChange} multiple disabled={true} />}
                    <br />
                  </div>
                </div>
                <div className=" my-3">
                  {
                    selectedfile.map((data, index) => {
                      const { id, filename, filetype, fileimage, datetime, filesize } = data;
                      return (
                        <div className="file-atc-box mb-5" key={id} 
                                  onMouseEnter={e => {
                                    setStyle({ display: 'inline' });
                                  }}
                                  onMouseLeave={e => {
                                    setStyle({ display: 'none' })
                                  }}>
                          <div className="file-detail">
                            <h8>{filename}</h8>
                          </div>
                          {
                            filename.match(/.(jpg|jpeg|png|gif|svg)$/i) ?
                            <div className="w-full flex justify-between">
                              <div className="w-full flex">
                              
                              {!disabled_ && <button className="mr-4 bg-red-800 hover:bg-red-900 text-white font-bold py-2 px-4 rounded ml-2" onClick={() => DeleteSelectFile(id)}
                                >Quitar</button>}
                                <SingleSource key={filename} src={fileimage} />
                              </div>
                              </div>
                                 :
                              <div className="w-full flex">
                                {!disabled_ && <button className="inline mr-4 bg-red-800 hover:bg-red-900 text-white font-bold py-2 px-4 rounded ml-2" onClick={() => DeleteSelectFile(id)}
                                >Quitar</button>}</div>
                              
                          }
                        </div>
                      )
                    })
                  }
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}